import React, { useEffect, useRef } from 'react';
import classnames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { DicomMetadataStore, MODULE_TYPES } from '@ohif/core';

import Dropzone from 'react-dropzone';
import filesToStudies from './filesToStudies';

import { extensionManager } from '../../App.tsx';

import { Icon, Button, LoadingIndicatorProgress } from '@ohif/ui';
//import './loader.css';
import './online.css';

type LocalProps = {
  modePath: string;
};

function Local({ modePath }: LocalProps) {
  let kovURL;
  const navigate = useNavigate();
  //const dropzoneRef = useRef();
  const [dropInitiated, setDropInitiated] = React.useState(false);

  // Initializing the dicom local dataSource
  const dataSourceModules = extensionManager.modules[MODULE_TYPES.DATA_SOURCE];
  const localDataSources = dataSourceModules.reduce((acc, curr) => {
    const mods = [];
    curr.module.forEach(mod => {
      if (mod.type === 'localApi') {
        mods.push(mod);
      }
    });
    return acc.concat(mods); // Felhalmozott érték visszaadása
  }, []);

  const firstLocalDataSource = localDataSources[0];
  const dataSource = firstLocalDataSource.createDataSource({});

  const microscopyExtensionLoaded = extensionManager.registeredExtensionIds.includes(
    '@ohif/extension-dicom-microscopy'
  );

  const onDrop = async acceptedFiles => {
    const studies = await filesToStudies(acceptedFiles, dataSource);

    const query = new URLSearchParams();

    if (microscopyExtensionLoaded) {
      // TODO: for microscopy, we are forcing microscopy mode, which is not ideal.
      //     we should make the local drag and drop navigate to the worklist and
      //     there user can select microscopy mode
      const smStudies = studies.filter(id => {
        const study = DicomMetadataStore.getStudy(id);
        return (
          study.series.findIndex(s => s.Modality === 'SM' || s.instances[0].Modality === 'SM') >= 0
        );
      });

      if (smStudies.length > 0) {
        smStudies.forEach(id => query.append('StudyInstanceUIDs', id));

        modePath = 'microscopy';
      }
    }

    // Todo: navigate to work list and let user select a mode
    //studies.forEach(id => query.append('StudyInstanceUIDs', id));
	studies.forEach(id => {
		query.append('StudyInstanceUIDs', id);
		console.log('Id :', id);
		kovURL="/viewer/dicomlocal?StudyInstanceUIDs="+id;
		});
    query.append('datasources', 'dicomlocal');

    //navigate(`/${modePath}?${decodeURIComponent(query.toString())}`);
	navigate(kovURL);
  };

  // Set body style
  useEffect(() => {
    document.body.classList.add('bg-black');
    return () => {
      document.body.classList.remove('bg-black');
    };
  }, []);

  return (
     <div className="dt_wrapper">
	    <img src="./help-mr-logo.png" alt="Help-Mr Logo" className="dt_logo" />
        <h1 className="dt_h1">Képi anyag megjelenítő</h1>
		<p className="dt_p">Kattintson a gombra, és válassza ki a képeket tartalmazó mappát (images)</p>
		<p className="dt_p">A képek csak a böngészőbe kerülnek betöltésre. Nem hagyják el az Ön eszközét.</p>
        <div className="dt_container">
			<Dropzone
				
				onDrop={acceptedFiles => {
				setDropInitiated(true);
				onDrop(acceptedFiles);
				}}
				noDrag
			>
			{({ getRootProps, getInputProps }) => (
				<div {...getRootProps()}  >
				{dropInitiated ? (
                  <div className="dt_loader">
                   <h4 id="betoltes" style={{ color: "white", fontFamily: "Montserrat, sans-serif", fontSize: "32px", letterSpacing: "-0.72px", lineHeight: "44px" }}>Betöltés folyamatban / Loading...</h4>
				  <div id="myloader" className="dt_spinner_loader">
				  </div>
				   
                  </div>
                ) : (
				   <div className="dt_box">
					Mappa betöltés <br /> Select folder
					<input
					{...getInputProps()}
					webkitdirectory="true"
					mozdirectory="true"
					/>
					</div>
				)}
				</div>
			     )}
			</Dropzone>
        </div>
		<p className="dt_p">Click on the button and select the folder containing the images</p>
		<p className="dt_p">Images will only be loaded into your browser.</p>
		<p className="dt_p">They will not leave your device.</p>
		<img src="./Group-62.png" alt="VÁLASSZA A MINŐSÉGET, FOGLALJON IDŐPONTOT ONLINE!-icon" className="dt_gep" />
    </div>
  );
}

export default Local;
